const getDynamicValueCommission = (string, value, cost) => {
  switch (string) {
    case "2": // Commissione amount
      return value; // La provvigione è già fornita
    case "3": // Commissione percentuale
      if (value >= 100) {
        alert("La percentuale di commissione non può essere uguale o superiore a 100%. È stata impostata automaticamente a 99%.");
        value = 99; // Imposta il valore massimo accettabile
      }
      return cost * (value / (100 - value)); // Calcolo provvigione come percentuale
    default:
      return 0;
  }
};

export default getDynamicValueCommission;
