const calculateValues = (
  totalValue, 
  quantity, 
  netProductionWeight, 
  grossProductionWeight
) => {
  const calculatedUnitValue = quantity > 0 ? totalValue / quantity : 0;
  const calculatedNetWeightValue =
    netProductionWeight > 0 ? totalValue / netProductionWeight : 0;
  const calculateGrossWeightValue =
    grossProductionWeight > 0 ? totalValue / grossProductionWeight : 0;

  return [calculatedUnitValue, calculatedNetWeightValue, calculateGrossWeightValue];
};

export default calculateValues;
