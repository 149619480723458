import React from "react";
import { Form } from "react-bootstrap";

const ValueTable = ({ values, placeholders }) => {
  // Funzione per formattare il valore in base all'indice
  const formatValue = (value, index) => {
    if (value === undefined) return 0;

    // Determina il formato in base all'indice
    if (index === 0) {
      return value.toFixed(0); // Total Value, arrotondato a 0 decimali
    } else if (index === 1) {
      return value.toFixed(6); // Unit Value, arrotondato a 6 decimali
    } else {
      return value.toFixed(2); // Altri valori, arrotondati a 2 decimali
    }
  };

  return (
    <>
      {/* Colonne con i valori formattati */}
      {values.map((value, index) => (
        <td key={index}>
          <Form.Group>
            <Form.Control
              className="text-end"
              type="text"
              placeholder={placeholders[index]}
              disabled
              readOnly
              value={formatValue(value, index)} // Mostra il valore formattato
            />
          </Form.Group>
        </td>
      ))}
    </>
  );
};

export default ValueTable;

