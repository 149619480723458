// Import react and router DOM
import React from "react";
import { useParams } from "react-router-dom";

//Import components
import ProductionSheetForm from "../../../components/productionSheetForm/ProductionSheetForm.jsx";
import {DashboardNavbar,DashboardNavbarMobile } from "../../../components/dashboardNav/dashboardNavbar.jsx";

//Import states, contexts and CSS
import { Row, Col, Card } from "react-bootstrap";

const ProductionSheetPage = () => {
  const { id } = useParams();
  
  return (
    <>
      <Row className="my-4">
      <Col lg={1} className="d-none d-lg-block">
          <DashboardNavbar />
        </Col>

        <Col xs={12} lg={10}>
          <Card className="mb-2" border="dark">
            <Card.Header>
              <h1 className={" text-center"}>Production Sheet</h1>
            </Card.Header>
          </Card>
          <ProductionSheetForm productionSheetId={id} />
        </Col>
        <Col lg={1}></Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} className="d-lg-none">
          <DashboardNavbarMobile />
        </Col>
      </Row>
    </>
  );
};

export default ProductionSheetPage;
