// Import react and router DOM
import React, { useState, useContext, useEffect } from "react";

//Import components

//Import utils and functions
import getDynamicValueCost from "../../../../utils/getDynamicValueCost";

//Import states, contexts and CSS
import Form from "react-bootstrap/Form";
import productionSheetContext from "../../../../store/productionSheetContext";
import styles from "./PriceTableRow.module.scss";
import Button from "react-bootstrap/Button";
import { DashCircle, ArrowDownCircle, ArrowUpCircle } from "react-bootstrap-icons";

const PriceTableRow = (props) => {
  //Distribuisco le props
  const { index, costLabel, costName } = props;
  const { newProductionSheet, setNewProductionSheet } = useContext(
    productionSheetContext
  );

  // Trova il costo corrispondente nel contesto
  const costItem =
    newProductionSheet.costs.find((costs) => costs.cost_id === costName) || {};

  // Stati locali
  const [costPrice, setCostPrice] = useState(costItem.costPrice || 0);
  const [unit, setUnit] = useState(costItem.unit || "");
  const [quantityValue, setQuantityValue] = useState(0);
  const [switchAttivato, setSwitchAttivato] = useState(costItem.switchActive);
  const [totalCostRow, setTotalCostRow] = useState(0);

  // Stato per il valore personalizzato
  // const [customValue, setCustomValue] = useState(
  //   newProductionSheet.costs[costName].customValue || ""
  // );


  // Aggiorna stati locali quando cambia il contesto
  useEffect(() => {
    setCostPrice(costItem.costPrice || 0);
    setUnit(costItem.unit || "1");
    setSwitchAttivato(costItem.switchActive || false);
  }, [newProductionSheet, costName]);

  // Calcola e aggiorna il valore dinamico e il costo totale
  useEffect(() => {
    if (switchAttivato) {
      const dynamicValue = getDynamicValueCost(unit,newProductionSheet);
      setQuantityValue(dynamicValue);
      setTotalCostRow(dynamicValue * costPrice);
    } else {
      setQuantityValue(0);
      setTotalCostRow(0);
    }
  }, [unit, costPrice, switchAttivato, newProductionSheet]);


  useEffect(() => {
    if (switchAttivato !== costItem.switchActive) {
      setSwitchAttivato(costItem.switchActive);
    }
  }, [newProductionSheet, costName, switchAttivato]);

  const handleSwitchChange = () => {
    const newSwitchAttivato = !switchAttivato;
    setSwitchAttivato(newSwitchAttivato);

    setNewProductionSheet((prevData) => ({
      ...prevData,
      costs: prevData.costs.map((cost) =>
        cost.cost_id === costName
          ? { ...cost, switchActive: newSwitchAttivato }
          : cost
      ),
    }));
  };

  const handleCostChange = (event) => {
    const newCost = parseFloat(event.target.value) || 0;
    setCostPrice(newCost);
    setNewProductionSheet((prevData) => ({
      ...prevData,
      costs: prevData.costs.map((cost) =>
        cost.cost_id === costName ? { ...cost, costPrice: newCost } : cost
      ),
    }));
  };

  const handleUnitChange = (event) => {
    const newUnit = event.target.value;
    setUnit(newUnit);
    setNewProductionSheet((prevData) => ({
      ...prevData,
      costs: prevData.costs.map((cost) =>
        cost.cost_id === costName ? { ...cost, unit: newUnit } : cost
      ),
    }));
  };

  const handleDeleteCost = () => {
    // Rimuovi il costo dal contesto basandoti sull'id
    setNewProductionSheet((prevData) => {
      
      // Filtra via l'elemento da eliminare
      const updatedCosts = prevData.costs.filter((cost) => cost.cost_id !== costName);
            
      return {
        ...prevData,
        costs: updatedCosts,
      };
    });
  };

  useEffect(() => {
  }, [newProductionSheet]);
  

  const moveCostUp = () => {
    // Rimuovi il costo dal contesto basandoti sull'id
    const updatedCosts = newProductionSheet.costs.slice();
    const costIndex = updatedCosts.findIndex((cost) => cost.cost_id === costName);

    if (costIndex > 0) {
      const tempCost = updatedCosts[costIndex];
      updatedCosts[costIndex] = updatedCosts[costIndex - 1];
      updatedCosts[costIndex - 1] = tempCost;

      setNewProductionSheet({
       ...newProductionSheet,
        costs: updatedCosts,
      });
    }
    console.log("Costo spostato in alto", newProductionSheet.costs);

  }

  const moveCostDown = () => {
    // Rimuovi il costo dal contesto basandoti sull'id
    const updatedCosts = newProductionSheet.costs.slice();
    const costIndex = updatedCosts.findIndex((cost) => cost.cost_id === costName);

    if (costIndex < updatedCosts.length - 1) {
      const tempCost = updatedCosts[costIndex];
      updatedCosts[costIndex] = updatedCosts[costIndex + 1];
      updatedCosts[costIndex + 1] = tempCost;

      setNewProductionSheet({
       ...newProductionSheet,
        costs: updatedCosts,
      });
    }
    console.log("Costo spostato in alto", newProductionSheet.costs);
  }


  return (
    <>
    <tr className={styles.priceTableRow}>
      <td>
        <Form.Switch
          type="switch"
          checked={switchAttivato}
          onChange={handleSwitchChange}
        />
      </td>
      <td>{index }</td>
      {/* <td>{index + 1}</td> */}

      <td>{costLabel}</td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Select onChange={handleUnitChange} value={unit}>
              <option value="1">Select an Option</option>
              <option value="2">Net Production Weight</option>
              <option value="3">Gross Production Weight</option>
              <option value="4">Quantity</option>
              <option value="5">Number of colors of Printing</option>
              <option value="custom">Custom Value</option>
            </Form.Select>
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Control
              className="text-end"
              type="text"
              placeholder="Value"
              disabled
              readOnly
              value={quantityValue}
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Group>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="cost"
              name="cost"
              onChange={handleCostChange}
              value={costPrice}
            />
          </Form.Group>
        ) : (
          <></>
        )}
      </td>
      <td>
        {switchAttivato ? (
          <Form.Control
            className="text-end"
            type="text"
            placeholder="Total Cost"
            value={totalCostRow}
            readOnly
          />
        ) : (
          <></>
        )}
      </td>
      
      <td>
        <Button variant="danger" onClick={handleDeleteCost}>
          <DashCircle size={16} />
        </Button>
      </td>
            
      <td>
        <Button variant="warning" onClick={moveCostUp}>
          <ArrowUpCircle size={16} />
        </Button>

      </td>
      <td>

        <Button variant="warning" onClick={moveCostDown}>
          <ArrowDownCircle size={16} />
        </Button>
      </td>
    </tr>

    </>
  );
};

export default PriceTableRow;
