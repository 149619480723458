const getDynamicValueProfit = (string, value, cost) => {
  switch (string) {
    case "2": // Profit Amount
      return value; // Il profitto è già fornito
    case "3": // MarkUp
      return cost * (value / 100); // Calcolo profitto da MarkUp
    case "4": // Margin
      if (value >= 100) {
        alert("Il margine percentuale non può essere uguale o superiore a 100%. È stato impostato automaticamente a 99%.");
        value = 99; // Imposta il margine massimo accettabile
      }
      return cost * (value / (100 - value)); // Calcolo profitto dal margine
    default:
      return 0;
  }
};

export default getDynamicValueProfit;
