import React, { createContext, useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

const ActiveUserContext = createContext();

export const ActiveUserProvider = ({ children }) => {
  const [activeUser, setActiveUser] = useState(null);
  const navigate = useNavigate();

  const login = (user, userId, token) => {
    setActiveUser(user);
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
  };

  const logout = () => {
    setActiveUser(null);
    localStorage.clear();

  };

  const contextValues = {
    activeUser,
    login,
    logout,
  };

  useEffect(() => {

    const checkAlreadyLoggedIn = async () => {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      if (token && userId && activeUser === null) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error("Errore nella connessione al server");
          }
          const user = await response.json();
          setActiveUser(user);
          navigate("/dashboard/productionSheetList");
        } catch (error) {
          console.log(error);
          navigate("/");
        }
      } else {
        setActiveUser(null);
        navigate("/");
      }
    };
    checkAlreadyLoggedIn();

    const checkTokenValidity = async () => {
      const currentMinute = new Date().toLocaleTimeString([], {
        minute: "2-digit",
        hour12: false,
      });
      console.log(`Checking token validity at ${currentMinute}...`);
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (token && userId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            // Se la richiesta fallisce, il token non è più valido
            logout();
            navigate("/");
          }
        } catch (error) {
          console.error("Errore durante la verifica del token:", error);
        }
      }
    };

    // Esegui la verifica ogni 5 minuti
    const intervalId = setInterval(checkTokenValidity, 5 * 60 * 1000);

    // Pulisci l'intervallo quando il componente si smonta
    return () => {
      clearInterval(intervalId);
      console.log("Token validity check interval cleared.");
    };
  }, [

  ]);



  return (
    <ActiveUserContext.Provider value={contextValues}>
      {children}
    </ActiveUserContext.Provider>
  );
};

export const useActiveUser = () => {
  const context = useContext(ActiveUserContext);
  if (!context) {
    throw new Error(
      "useActiveUser deve essere utilizzato all'interno di un ActiveUserProvider"
    );
  }
  return context;
};
