// Import react and router DOM



const emptyProductionSheet = {
  bagType: "",
  userId: "",
  width: 10,
  height: 15,
  thickness: 50,
  materialSpecificWeight: 0.92,
  weight: 0,
  bottomGussetOpen: 0,
  sideGussetOpen: 0,
  upperFlapOpen: 2,
  quantity: 200000,
  quantityUnit: "",
  cuttingDie: 0,
  netProductionWeight: "",
  grossProductionWeight: "",
  outputTolerance: 15,
  outputToleranceUnit: "",
  toleranceWeight: "",
  materialPrice: "",
  tracksDirection: 1,
  tracksNumber: 1,
  reelBand: "",
  meterWeightRatio: "",
  grossProductionMeters: "",
  colorsNumber: 1,
  costs: [
    { cost_id: 1, label: "Material", costPrice: 1000, unit: "5", totalRow: 0,switchActive: true },
    { cost_id: 2, label: "Extrusion", costPrice: 0, unit: "0", totalRow: 0,switchActive: false },
    { cost_id: 3, label: "Printing", costPrice: 0, unit: "0", totalRow: 0,switchActive: false },
    { cost_id: 4, label: "Electricity", costPrice: 0, unit: "0", totalRow: 0,switchActive: false },
    { cost_id: 5, label: "Cutting", costPrice: 0, unit: "0", totalRow: 0,switchActive: false },
    { cost_id: 6, label: "Transport", costPrice: 0, unit: "0", totalRow: 0,switchActive: false },
  ],
  profit: { profitUnit: "", profitAmount: 0 },
commission: {commissionUnit:"", commissionAmount: 0 },
};

  const emptyCreateAccountForm = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    countryOfResidence: null,
    email: "",
    password: "",
  };

  export {emptyProductionSheet,emptyCreateAccountForm}